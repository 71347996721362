import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import NDPLogo from '../../Assets/images/NDPLogo.png'
import './EpscitynantucketMA.css'

import {  withRouter } from 'react-router-dom';
import Frame from '../Frame/Frame';
//import Maintenancev2 from '../Maintenancev2/Maintenancev2';

function EpscitynantucketMA(props)
{
 //   if (props.data?.[0]?.Closed)
 //   {
 //       return (
 //           <Maintenancev2/>
 //       );
 //   }
 
 
// 					 setTimeout(() => {
 //                   <div className="epscitynantucket-ma-frame-container">
 //                       <Frame
 //                           id="epscitynantucket-ma-frame-container"
//                            link={props.data[0]['Report Link'] || ""}
//                            checkIframeCondition={true}
//                            title={props.data[0].Customer}
 //                       />
//                    </div>
					
//						}, 20000)
 
    return (
        <>
            <Header
                filter={false}
                Logo={NDPLogo}
                isFeedbackBtn={true}
            />
            {
                props.data && props.data.length>0 && props.data[0]['Report Link']
                    ?
					
                    <div className="epscitynantucket-ma-frame-container">
                        <Frame
                            id="epscitynantucket-ma-frame-container"
                            link={props.data[0]['Report Link'] || ""}
                            checkIframeCondition={true}
                            title={props.data[0].Customer}
                        />
                    </div>					:
                    // :"No data"
                    null
            }
            <Footer fromSurveyContainer={true}/>
        </>
    )
}

export default  withRouter(EpscitynantucketMA);
import React from 'react'
import {  withRouter } from 'react-router-dom';

function Home(props)
{

    return (
        <>
            <div>
                Home Page here....
            </div>
        </>
    )
}

export default  withRouter(Home);
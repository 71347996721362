import React, { useState, useEffect } from "react";
import {  withRouter } from 'react-router-dom';
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckICon from "../../Assets/images/check.png";
//import CaptchaValidator from "../CaptchaValidator/CaptchaValidator";
import { validateCaptcha } from 'react-simple-captcha';
import { nameOfTHePage } from "../../utils/utils";
import FAQPDF from '../../Assets/pdf/Nantucket_EPS_2022_ FAQ.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      padding: "10px",
      width: "100%",
    },
  },
}));
let errorObject= {'feedback':'','captcha':''};
function Header(props) {
  const [selectOpenClose, setselectOpenClose] = useState({
    geography: false,
    region: false,
  });
  const [geoGraphySelect, setGeoGraphySelect] = useState("");
  const [geoGraphySelectValues, setGeoGraphySelectValues] = useState([]);
  const [regionSelect, setRegionSelect] = useState("");
  const [regionSelectValues, setRegionSelectValues] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [feedback, setFeedback] = useState("");
  const [errObj, setErrObj] = useState({'feedback':'','captcha':''});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [captcha, setCaptcha] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles();

  useEffect(() => {
    let uniqueGeo = [];

    if (props.data && props.data.length > 0) {
      props.data.forEach((d) => {
        if (!uniqueGeo.includes(d["Report Geo"]) && d["Report Geo"]) {
          uniqueGeo.push(d["Report Geo"]);
        }
      });

      setGeoGraphySelectValues(uniqueGeo);
      setGeoGraphySelect(uniqueGeo[0]);
    }
  }, [props.data]);
  const Toggle = (flag) => {
    if (flag) {
      return false;
    } else {
      return true;
    }
  };

  const handleOpenClose = (name) => {
    if (name === "region") {
      setselectOpenClose({
        ...selectOpenClose,
        region: Toggle(selectOpenClose.region),
      });
    } else {
      setselectOpenClose({
        ...selectOpenClose,
        geography: Toggle(selectOpenClose.geography),
      });
    }
  };
  const handleChange = (e, name) => {
    if (e.target.value) {
      if (name === "geography") setGeoGraphySelect(e.target.value);
      if (name === "region") setRegionSelect(e.target.value);
    }
  };
  useEffect(() => {
    if (props.data && props.data.length > 0) {
      let uniqueRegion = [];
      props.data.forEach((d) => {
        if (geoGraphySelect === d["Report Geo"]) {
          if (!uniqueRegion.includes(d.Region) && d.Region) {
            uniqueRegion.push(d.Region);
          }
        }
      });
      setRegionSelectValues(uniqueRegion);
      setRegionSelect(uniqueRegion[0]);
    }
  }, [geoGraphySelect, props.data]);
  useEffect(() => {
    if (props.data && props.data.length > 0) {
      for (let i = 0; i < props.data.length; i++) {
        if (
          props.data[i].Region === regionSelect &&
          props.data[i]["Report Geo"] === geoGraphySelect
        ) {
          props.setActiveLink(props.data[i]["Report Link"]);
          break;
        } else {
          props.setActiveLink(null);
        }
      }
    }
  }, [geoGraphySelect, regionSelect, props.data]);
  const handleClose = () => {
    setOpen(!open); 
    setIsSuccess(false)
    setFeedback("");
    setErrObj({'feedback':'','captcha':''});
    errorObject = {'feedback':'','captcha':''};
    setIsValid(false)
  };

  const checkError = () => {
    let err = {...errObj};
    if (!feedback) {
      err.feedback = "please enter the feedback";
    } else {
      err.feedback = "";
    }
    // if (!captcha) {
    //   err.captcha = "please enter the captcha text";
    // } else if(captcha.length!==6){
    //   err.captcha = "Please enter the 6 character captcha";
    // }else {
    //   err.captcha = "";
    // }
    errorObject=err;
    setErrObj(err);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    checkError();
    if (errorObject.feedback === "" || errorObject.captcha === "") {
     
      if (validateCaptcha(captcha) == true) {
      
      if(feedback){
        setIsLoading(true);       
        const pageName = nameOfTHePage(props.location.pathname.split("/")[1]);
        fetch("https://feedback-email-backend.herokuapp.com/send_email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ feedback, pageName }),
        })
          .then((res) => {
            setIsValid(true);
            setIsSuccess(true);
            setFeedback("");
           setIsLoading(false);
          })
          .catch((e) => {
            console.log("error:>", e);
          });
      }
      } else {
        setIsValid(false)
        setIsSuccess(false)
        errorObject={...errorObject,captcha:'Invalid Captcha'};
        setErrObj({...errObj,captcha:'Invalid Captcha'})
      }

    }

    // handleClose();

  }
  const handleClickOpen = () => {
    setOpen(!open);
  };
  const handleTextChange = (e) => {
    checkError();
    setFeedback(e.target.value.trim());
  };
  return (
    <>
      <div className="header">
        <img src={props.Logo} alt="Carmel logo" />

        <Dialog
          className="dialog1"
          open={open}
          // onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          { isLoading ?
            <div className="loader">
              <CircularProgress />
            </div>
          :null}

          <>
            {isSuccess  ? (
              <div>
                {" "}
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <div className="sucess-feedback">
                  <img src={CheckICon} style={{}} />
                  <h1>Thank you for your feedback</h1>
                  <p>
                    Your feedback is highly valued and is used to improve our toolkits and services.
                  </p>
                </div>
              </div>
            ) : (
              <>
                <DialogTitle id="form-dialog-title">
                  <div style={{display:"flex"}}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <p style={{color:'#2796d3'}}>This product is for you! <br/>That means we need your help to improve it.</p>
                    <IconButton className='closeIcon' aria-label="close" onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <form
                    id="myForm"
                    className={classes.root}
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <p>
                        What would you like to tell us about how we are doing well or how we could improve?
                      </p>
                      <TextField
                        id="outlined-multiline-static"
                        placeholder={!feedback ? "Enter your feedback/suggestion here..." : null}
                        multiline
                        rows={5}
                        // defaultValue="Default Value"
                        variant="outlined"
                        onChange={(e) => handleTextChange(e)}
                        error={errorObject.feedback ? true : false}
                        helperText={
                          errorObject.feedback ? errorObject.feedback : ""
                        }
                        fullWidth
                      />
                      {/*{!isSuccess && (*/}
                      {/*  <CaptchaValidator*/}
                      {/*    erorr={errorObject}*/}
                      {/*    captcha={captcha}*/}
                      {/*    isValid={isValid}*/}
                      {/*    setCaptcha={setCaptcha}*/}
                      {/*  />*/}
                      {/*)}*/}
                    </div>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Send
                      </Button>
                    </DialogActions>
                  </form>
          </DialogContent>
          </>
            )}
          </>

        </Dialog>

        {props.filter && (
          <div className="chart-dropdown">
            <div className="coustom-drop-down">
              <span>Geography</span>
              <FormControl>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={selectOpenClose.geography}
                  className="custom-drop"
                  placeholder="Origin"
                  onClose={() => handleOpenClose("geography")}
                  onOpen={() => handleOpenClose("geography")}
                  value={geoGraphySelect}
                  onChange={(e) => handleChange(e, "geography")}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {geoGraphySelectValues &&
                    geoGraphySelectValues.length > 0 &&
                    geoGraphySelectValues.map((d, i) => (
                      <MenuItem key={i} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            {regionSelectValues && regionSelectValues.length > 0 && (
              <div className="coustom-drop-down">
                <FormControl>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={selectOpenClose.region}
                    className="custom-drop"
                    placeholder="Origin"
                    onClose={() => handleOpenClose("region")}
                    onOpen={() => handleOpenClose("region")}
                    value={regionSelect}
                    onChange={(e) => handleChange(e, "region")}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {regionSelectValues.map((d, i) => (
                      <MenuItem key={i} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        )}
        {props.isFeedbackBtn ? (
          <div className="feedback-wrapper">
            <Button variant="outlined" onClick={handleClickOpen}>
              Feedback
            </Button>
          </div>
        ) : null}

        <div className="Faqpart">
          <a href={FAQPDF} target="_blank" className="Faqlink">
          <Button variant="outlined" className="FaqButton">
            FAQ
          </Button>
          </a>
        </div>
      </div>
    </>
  );
}

export default withRouter(Header)
import React,{useEffect} from 'react';
import {Button}  from '@material-ui/core/'
import CarmelLogo from '../../Assets/images/CDPLogo.png'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Maintenance.scss'
import MaintenanceLogo from '../../Assets/images/Maintennance.png'
import {withRouter} from 'react-router-dom'


function Maintenance(props) {
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight-180);
  useEffect(() => {
        const handleResize = () => setWindowHeight(window.innerHeight-180);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
   
    const handleButtonClick=(e)=>{
        e.preventDefault();
        props.history.push('/');
    }
    return (
        <div>
           {/* <Header
           filter={false}
           Logo={CarmelLogo}
           /> */}
           <div className='main-wrapper' style={{height:windowHeight<650 && window.innerWidth>768?650:windowHeight}}>
               <h1>Oops. Something went wrong. Click&nbsp;
                    <a
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                    alt="Nantucket Data Platform"
                    href="https://population.nantucketdataplatform.com/"
                    >
                        this link
                    </a> to try again.
                </h1>
               {/*<div className='button-wrapper'>*/}
               {/*     <Button onClick={handleButtonClick} variant="outlined" size="large" color="primary" >Go to Home</Button>*/}
               {/*</div>*/}
               <div className='img-wrapper'>
               <img src={MaintenanceLogo}  alt="Maintenance logo" />
               </div>
           </div>
           {/* <Footer fromSurveyContainer={false}/> */}
            
        </div>
    )
}
export default  withRouter(Maintenance)
export const checkIframeLoaded = (props) => {
  try {
   setTimeout(() => {
      var iframe = document.getElementById(props.id);
      var iframeDoc = iframe.contentDocument || iframe.contentWindow;
      var docLength = Object.keys(iframeDoc).length || undefined;
      var redirect = false;
     
      if (iframe && typeof iframe === "object" && iframe.src && props.link!=='none') {
          if (docLength === undefined) {
            redirect = true;
          }
      } else {
        redirect = true;
      }
      if (redirect) {
        props.history.push("/maintenancev2");
        redirect = false;
      }
   }, 100000);
  } catch (e) {
    console.log("error:>", e);
  }
};


export const nameOfTHePage = (str) => {
  var i, fragments = str.split('_');
  for (i=0; i<fragments.length; i++) {
    fragments[i] = fragments[i].charAt(0).toUpperCase() + fragments[i].slice(1);
  }
  return fragments.join(' ');
}
import React from "react";
import FooterLogo from "../../Assets/images/NDPLogo.png";
import FAQPDF from "../../Assets/pdf/Nantucket_EPS_2022_ FAQ.pdf";
import Button from "@material-ui/core/Button";
export default function Footer(props) {
   
  return (
    <>
       <footer className="footer-wrapper">
        <div className="footer-logo">
          <img src={FooterLogo} alt="community dataplatforms logo" />
        </div>
           <div className="FaqpartFooter">
               <a href={FAQPDF} target="_blank" className="Faqlink">
                   <Button variant="outlined" className="FaqButton">
                       FAQ
                   </Button>
               </a>
           </div>
        <div className="footer-text">
          <p style={{paddingBottom:props.fromSurveyContainer?'0px':'21px' }}>
            ©{new Date().getFullYear()}
            <a href="https://www.communitydataplatforms.com/" rel="noreferrer" target="_blank">
              &nbsp;Community Data Platforms
            </a>
            . All rights reserved.{" "}
          </p>
          {props.fromSurveyContainer &&
          <div className="link-text">
            <a href="https://survey.communitydataplatforms.com/privacy_policy/" target="_blank">Privacy Policy</a>
            <p className='seprator-line'>|</p>          
            <a href="https://communitydataplatforms.com/terms/" target="_blank">Terms of use</a>
          </div>}
        </div>
      </footer>
    </>
  );
}

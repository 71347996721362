import logo from './logo.svg';
import './App.scss';

import { BrowserRouter as Router, Route ,Switch,Redirect} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import EpscitynantucketMA from "./components/EpscitynantucketMA/EpscitynantucketMA";
import Maintenance from "./components/Maintenance/Maintenance";
import Maintenancev2 from "./components/Maintenancev2/Maintenancev2";
import React, {useState , useEffect} from "react";

import Home from "./components/Home/Home";

let coustomers= {
  //  "epscitynantucketma": "EpscitynantucketMA" ////for display in "epscitynantucketma" route
    "": "EpscitynantucketMA" ///// for display directlly in home page
}
let currentCoustomer=null;
let ReportJsonData=[];

function App() {

  const history = createBrowserHistory();
  const  [currentTitle,setCurrentTitle]=useState('')
  const [coustomerData, setCoustomerData] = useState([]);
  const pathName=history.location.pathname;

    const getData = () => {
        fetch(`${process.env.PUBLIC_URL}/Data/reports.json`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                ReportJsonData=[];
                ReportJsonData=jsonData;
                let paths=pathName.trim().split('/');
                currentCoustomer=coustomers[paths[paths.length-1]];
                if(!currentCoustomer){
                    currentCoustomer=coustomers[paths[paths.length-2]];
                }
                setCurrentTitle(currentCoustomer)
                setCoustomerData(jsonData.filter((d)=>d.Customer.includes(currentCoustomer)));
            });
    };
    useEffect(()=>{
        getData();
    },[])

  return (
      // <Router history={history}>
      <Router>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathName.slice(0, -1)} />
            {/* Enable this route when we need to display home page as a front of page */}

            {/*<Route*/}
            {/*        exact*/}
            {/*        path="/"*/}
            {/*        component={() => <Home data={ReportJsonData} />}*/}
            {/*/>*/}
            //<Route exact path="/maintenancev2" component={() => <Maintenancev2 />} />
            //<Route exact path="/maintenance" component={() => <Maintenance />} />
            <Route
                  exact
                  path="/"  ///for display directlly in front on the page
                //  path="/epscitynantucketma"
                  component={() => <EpscitynantucketMA data={coustomerData} />}
            />
        </Switch>
      </Router>
  );
}

export default App;

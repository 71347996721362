import React,{useEffect} from 'react';
import {Button}  from '@material-ui/core/'
import './Maintenancev2.scss'
import MaintenanceLogo from '../../Assets/images/Maintennance.png'
import {withRouter} from 'react-router-dom'
import NDPLogo from '../../Assets/images/NDPLogo.png'
import Header from "../Header/Header";
import Footer from '../Footer/Footer'


function Maintenancev2(props) {
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight-180);
    useEffect(() => {
        const handleResize = () => setWindowHeight(window.innerHeight-180);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleButtonClick=(e)=>{
        e.preventDefault();
        props.history.push('/');
    }
    return (
        <div>
            <Header
                filter={false}
                Logo={NDPLogo}
                isFeedbackBtn={true}
            />
            <div className='main-wrapper' style={{height:windowHeight<650 && window.innerWidth>768?650:windowHeight}}>
                <h1>
                    Sorry, something went wrong.&nbsp;
                    <a
                        href="https://population.nantucketdataplatform.com/"
                        target="_self"
                        rel="noreferrer"
                        >Click here to try again.
                    </a>
                </h1>
                {/*<div className='button-wrapper'>*/}
                {/*     <Button onClick={handleButtonClick} variant="outlined" size="large" color="primary" >Go to Home</Button>*/}
                {/*</div>*/}
                <div className='img-wrapper'>
                    <img src={MaintenanceLogo}  alt="Maintenance logo" />
                </div>
            </div>
             <Footer fromSurveyContainer={false}/>

        </div>
    )
}
export default  withRouter(Maintenancev2)
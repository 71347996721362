import React, { useState } from "react";
import { checkIframeLoaded } from "../../utils/utils";
import { withRouter } from "react-router";
import "./Frame.scss";
function Frame(props) {
  const [loading, setLoading] = useState(true);
  return (
    <>
      {loading && <div class="loader7"></div>}
      <iframe
        id={props.id}
        src={props.link}
        title={props.title}
        frameBorder="0"
        scrolling="no"
        onLoad={() => {
            props.checkIframeCondition && checkIframeLoaded(props);
            setLoading(false);
        }}
      ></iframe>
    </>
  );
}
export default withRouter(Frame);
